import React from 'react';
import { FormattedMessage } from 'react-intl';

import OpenInvoiceCard from 'components/Core/OpenInvoices/Card/OpenInvoiceCard';
import { OpenInvoiceCardProps } from 'components/Core/OpenInvoices/Notice/OpenInvoicesNotice';

export interface Props {
  invoices: Array<OpenInvoiceCardProps>;
  isSuccessScreen: boolean;
  autoPayInfo?: { isAutoPay: boolean; isAutoPayOn: boolean; companyName: string };
}

const OpenInvoiceList: React.FC<Props> = ({ invoices, isSuccessScreen, autoPayInfo }) => {
  const cards = invoices.map((invoice, index) => {
    return (
      <OpenInvoiceCard
        key={invoice.docNumber}
        index={index}
        {...invoice}
        isSuccessScreen={isSuccessScreen}
        autoPayInfo={autoPayInfo}
      />
    );
  });

  return (
    <div className="cards-container" data-testid="openinvoicelist">
      {autoPayInfo?.isAutoPay && (
        <div className="sub-header">
          <FormattedMessage
            id="AUTO_PAY_SUB_HEADER"
            defaultMessage="You have upcoming payments"
            values={{ payments: invoices.length, companyName: autoPayInfo.companyName }}
          />
        </div>
      )}
      {cards}
      <style jsx>{`
        .cards-container {
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 300px;
          width: auto;

          .sub-header {
            position: relative;
            font-size: 14px;
            min-height: 60px;
            padding: 16px 20px 4px 20px;
            &::after {
              content: '';
              position: absolute;
              border-bottom: 2px solid white;
              bottom: -2px;
              left: 0;
              right: 0;
              z-index: 1;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default OpenInvoiceList;
