import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'styles/cp';
import Button from 'components/Shared/Button/Button';
import Spinner from 'components/Shared/Spinner/Spinner';

const { bool, func, string, oneOf, node, number } = PropTypes;

const ProgressButton = (props) => {
  let buttonProps = Object.keys(props)
    .filter((key) => ['spinner', 'spinnerWidth', 'hideContentOnSpinner'].indexOf(key) < 0)
    .reduce((obj2, key) => ((obj2[key] = props[key]), obj2), {});

  const {
    buttonType = 'default',
    contentColor,
    children = null,
    size = 'standard',
    spinner,
    spinnerWidth,
    hideContentOnSpinner = false,
  } = props;

  let spinnerColor = contentColor;
  if (!spinnerColor && spinner) {
    switch (buttonType) {
      case 'default':
        spinnerColor = colors.gray;
        break;
      case 'tertiary':
        spinnerColor = colors.intuit_blue;
        break;
      default:
        spinnerColor = colors.white;
    }
  }
  let spinnerSize = spinnerWidth;
  if (!spinnerSize && spinner) {
    switch (size) {
      case 'mini':
        spinnerSize = 13;
        break;
      case 'medium':
        spinnerSize = 20;
        break;
      default:
        spinnerSize = 25;
    }
  }
  return (
    <Button {...buttonProps} aria-label="Approval button">
      <span className="progress-btn-w">
        {spinner && (
          <span className="spinner">
            <Spinner
              width={spinnerSize}
              strokeWidth={5}
              color={spinnerColor}
              bgColor={colors.transparent}
              aria-label="Spinner"
            />
          </span>
        )}
        {!(hideContentOnSpinner && spinner) && children}
      </span>

      <style jsx>{`
        .progress-btn-w {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
          line-height: 20px;
          .spinner {
            margin-right: ${hideContentOnSpinner ? '0px' : '5px'};
          }
        }
      `}</style>
    </Button>
  );
};

ProgressButton.propTypes = {
  className: string,
  children: node,
  disabled: bool,
  overlayClassName: string,
  onBlur: func,
  onClick: func,
  onFocus: func,
  onMouseDown: func,
  size: oneOf(['mini', 'medium', 'standard', 'jumbo', 'responsive']),
  width: string,
  height: string,
  buttonType: oneOf(['default', 'dark', 'primary', 'invert', 'paypal', 'applepay', 'tertiary']),
  contentColor: string,
  backgroundColor: string,
  borderColor: string,
  // eslint-disable-next-line
  'aria-label': string,

  hideContentOnSpinner: bool,
  spinner: bool,
  spinnerWidth: number,
};

export default ProgressButton;
