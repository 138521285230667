import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

const Check: React.FC<IconBasePropsWithColor> = ({
  color = '#6B6C72',
  width = 24,
  height = 24,
  className = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17 4H15.816C15.6099 3.41709 15.2288 2.91209 14.7247 2.55409C14.2206 2.19608 13.6183 2.00256 13 2H11C10.3817 2.00256 9.77937 2.19608 9.2753 2.55409C8.77123 2.91209 8.39008 3.41709 8.184 4H7C6.20435 4 5.44129 4.31607 4.87868 4.87868C4.31607 5.44129 4 6.20435 4 7V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V7C20 6.20435 19.6839 5.44129 19.1213 4.87868C18.5587 4.31607 17.7956 4 17 4ZM11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H8V7C8 7.26522 8.10536 7.51957 8.29289 7.70711C8.48043 7.89464 8.73478 8 9 8H15C15.2652 8 15.5196 7.89464 15.7071 7.70711C15.8946 7.51957 16 7.26522 16 7V6H17C17.2652 6 17.5196 6.10536 17.7071 6.29289C17.8946 6.48043 18 6.73478 18 7V19Z"
        fill={color}
      />
      <path
        d="M13.543 11.293L11.25 13.586L10.457 12.793C10.2684 12.6108 10.0158 12.51 9.75361 12.5123C9.49141 12.5146 9.2406 12.6198 9.05519 12.8052C8.86978 12.9906 8.76461 13.2414 8.76234 13.5036C8.76006 13.7658 8.86085 14.0184 9.04301 14.207L10.543 15.707C10.7305 15.8945 10.9848 15.9998 11.25 15.9998C11.5152 15.9998 11.7695 15.8945 11.957 15.707L14.957 12.707C15.1392 12.5184 15.24 12.2658 15.2377 12.0036C15.2354 11.7414 15.1302 11.4906 14.9448 11.3052C14.7594 11.1198 14.5086 11.0146 14.2464 11.0123C13.9842 11.01 13.7316 11.1108 13.543 11.293Z"
        fill={color}
      />
    </svg>
  );
};

export default Check;
